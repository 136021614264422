import BadWordsFilterContext, {
  withBadWordsFilter
} from './BadWordsFilterContext';
import BadWordsFilter from './BadWordsFilter';

export default BadWordsFilter;
export {
  BadWordsFilterContext,
  withBadWordsFilter
};
