import React from 'react';

const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {
      firebase =>
        <Component {...props} firebase={firebase} />
    }
  </FirebaseContext.Consumer>
);
const FirebaseContext = React.createContext(null);

export default FirebaseContext;
export {
  withFirebase
};