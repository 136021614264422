import React, {
  Component
} from 'react';
import {
  Modal,
  ModalBody
} from 'reactstrap';
import LoadingIcon from './LoadingIcon';

class LoadingOverlayModal extends Component {
  render = () => {
    return (
      <>
        <Modal isOpen backdrop={false} size="xl" modalClassName="loading" fade={false}>
          <ModalBody className="text-warning text-center">
            <LoadingIcon />
            <h1>Loading...</h1>
            <p>
              Has this page not loaded after a long time waiting? Send us an email so we know.{' '}
              <a href="mailto:PWA@MaiMarae.nz?subject=Tolaga Bay Inn Website" title="Send us an email">
                PWA&#64;MaiMarae&#46;nz
              </a>
            </p>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default LoadingOverlayModal;