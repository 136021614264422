import React, {
  Component,
  lazy
} from 'react';
import {
  HashRouter,
  Route,
  Switch
} from 'react-router-dom';
import './App.scss';
import {
  withAuthentication
} from './components/Firebase';
import LoadingOverlayModal from './components/App/LoadingOverlayModal';
import BadWordsFilter, {
  BadWordsFilterContext
} from './components/BadWordsFilter';

const Login = lazy(() => import('./views/Pages/Login'));
const Forgot = lazy(() => import('./views/Pages/Forgot'));
const Register = lazy(() => import('./views/Pages/Register'));
const Page404 = lazy(() => import('./views/Pages/Page404'));
const Page500 = lazy(() => import('./views/Pages/Page500'));
const DefaultLayout = lazy(() => import('./containers/DefaultLayout'));

class App extends Component {
  render() {
    return (
      <BadWordsFilterContext.Provider value={new BadWordsFilter({
        firebase: this.props.firebase
      })}>
        <HashRouter>
          <React.Suspense fallback={<LoadingOverlayModal />}>
            <Switch>
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route exact path="/forgot" name="Forgot Password Page" component={Forgot} />
              <Route exact path="/register" name="Register Page" component={Register} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <Route path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </BadWordsFilterContext.Provider>
    );
  }
}

export default withAuthentication(App);
