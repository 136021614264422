import React from 'react';
import {
  withRouter
} from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';
import {
  compose
} from 'recompose';
import {
  AuthUserContext,
  withFirebase
} from './index';
import LoadingOverlayModal from '../App/LoadingOverlayModal';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount = async () => {
      this.listener = await this.props.firebase.authUserListener(this.handleAuthUserListenerNext, this.handleAuthUserListenerFallback);
    }
    componentWillUnmount = () => {
      this.listener();
    }
    handleAuthUserListenerNext = authUser => {
      if (!condition(authUser)) {
        this.props.history.push('/login');
      }
    }
    handleAuthUserListenerFallback = () => {
      this.props.history.push('/login');
    }
    render = () => {
      return (
        <AuthUserContext.Consumer>
          {
            authUser =>
              !authUser
                ? <LoadingOverlayModal />
                : condition(authUser)
                  ? <Component {...this.props} authUser={authUser} />
                  : (
                    <>
                      <div className="app">
                        <Container>
                          <Row>
                            <Col className="mx-auto" md="8">
                              <Card className="no-transition">
                                <CardHeader>
                                  <h1 className="text-danger">Mai Marae PWA Access Denied!</h1>
                                </CardHeader>
                                <CardBody>
                                  <h3>
                                    Need to discuss your options? Send us an email so we know.{' '}<a href="mailto:PWA@MaiMarae.nz?subject=Mai Marae PWA Access Denied!" title="Send us an email" style={{ color: '#51bcda !important' }}>PWA&#64;MaiMarae&#46;nz</a>
                                  </h3>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </>
                  )
          }
        </AuthUserContext.Consumer>
      );
    }
  }
  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

export default withAuthorization;
