import React, {
  Component
} from 'react';

class LoadingIcon extends Component {
  render = () => {
    const {
      size
    } = this.props;
    let iconSize = null;
    switch (size) {
      case 'sm':
        iconSize = '';
        break;
      case 'lg':
        iconSize = 'fa-5x';
        break;
      default:
        iconSize = 'fa-3x';
    }
    return (
      <span className={`fa fa-spin fa-spinner ${iconSize}`}  {...this.props} />
    );
  }
}

export default LoadingIcon;