import React from 'react';

const BadWordsFilterContext = React.createContext(null);

export const withBadWordsFilter = Component => props => (
  <BadWordsFilterContext.Consumer>
    {
      badWordsFilter =>
        <Component {...props} badWordsFilter={badWordsFilter} />
    }
  </BadWordsFilterContext.Consumer>
);

export default BadWordsFilterContext;