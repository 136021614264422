import * as firebaseApp from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import {
  /* systemAdminRole,
  adminRole, */
  basicRole
} from '../../firebaseDbRoles.js';
import swal from 'sweetalert2';
import * as mmlWebAppDevConfig from '../../firebaseConfig.js';

/*  const firebaseConfig = process.env.NODE_ENV === 'production'
    ? mmlWebAppProdConfig
    : mmlWebAppDevConfig; */
const firebaseConfig = mmlWebAppDevConfig.default;

class Firebase {
  constructor() {
    this.app = firebaseApp.apps.length
      ? firebaseApp.app()
      : firebaseApp.initializeApp(firebaseConfig);
    this.auth = firebaseApp.auth();
    this.db = firebaseApp.database();
    this.storage = firebaseApp.storage();
    this.emailAuthProvider = firebaseApp.auth.EmailAuthProvider;
    this.facebookProvider = new firebaseApp.auth.FacebookAuthProvider();
    this.googleProvider = new firebaseApp.auth.GoogleAuthProvider();
  }

  /*** Auth APIs ***/
  createUserWithEmailAndPassword = async (email, password, createUserWithEmailAndPassword_Completed) => {
    const authUser = await this.auth.createUserWithEmailAndPassword(email, password),
      {
        uid,
        providerData
      } = authUser.user,
      now = new Date();
    await this.sendEmailVerification();
    await this.saveDbUser({
      active: true,
      created: now.toString(),
      createdBy: uid,
      displayName: '',
      email: email,
      photoURL: '',
      providerData: providerData,
      roles: {
        basicRole
      },
      uid: uid,
      updated: now.toString(),
      updatedBy: uid
    }, createUserWithEmailAndPassword_Completed);
    return authUser;
  }

  signInWithEmailAndPassword = async (email, password) => {
    return await this.auth.signInWithEmailAndPassword(email, password);
  }

  signInWithFacebook = async signInWithFacebook_Completed => {
    const authUser = await this.auth.signInWithPopup(this.facebookProvider),
      {
        uid,
        displayName,
        email,
        photoURL,
        providerData
      } = authUser.user,
      now = new Date();
    await this.saveDbUser({
      active: true,
      created: now.toString(),
      createdBy: uid,
      displayName: displayName,
      email: email,
      photoURL: photoURL,
      providerData: providerData,
      roles: {
        basicRole
      },
      uid: uid,
      updated: now.toString(),
      updatedBy: uid
    }, signInWithFacebook_Completed);
    return authUser;
  }

  signInWithGoogle = async signInWithGoogle_Completed => {
    const authUser = await this.auth.signInWithPopup(this.googleProvider),
      {
        uid,
        displayName,
        email,
        photoURL,
        providerData
      } = authUser.user,
      now = new Date();
    await this.saveDbUser({
      active: true,
      created: now.toString(),
      createdBy: uid,
      displayName: displayName,
      email: email,
      photoURL: photoURL,
      providerData: providerData,
      roles: {
        basicRole
      },
      uid: uid,
      updated: now.toString(),
      updatedBy: uid
    }, signInWithGoogle_Completed);
    return authUser;
  }

  signOut = async () => {
    return await this.auth.signOut();
  }

  sendPasswordResetEmail = async email => {
    return await this.auth.sendPasswordResetEmail(email);
  }

  sendEmailVerification = async () => {
    return await this.auth.currentUser.sendEmailVerification();
    /* return await this.auth.currentUser.sendEmailVerification({
        url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    }); */
  }

  reauthenticate = async currentPassword => {
    const user = this.auth.currentUser,
      userCredentials = await this.emailAuthProvider.credential(user.email, currentPassword);
    return await user.reauthenticateWithCredential(userCredentials);
  }

  getUserCredentials = async providerId => {
    let result = null,
      errorMessage = null;
    try {
      if (providerId === 'password') {
        result = await swal.fire({
          title: 'Current Password',
          input: 'password',
          inputPlaceholder: 'Enter your current password',
          showCancelButton: true,
          footer: 'Need to verify your credentials before continuing...'
        });
        if (!!result.value) {
          return await this.reauthenticate(result.value);
        }
      } else {
        const provider = providerId === 'facebook.com'
          ? this.facebookProvider
          : providerId === 'google.com'
            ? this.googleProvider
            : null
        return await this.auth.signInWithPopup(provider);
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      console.log(`Get User Credentials Error: ${errorMessage}`);
      return errorMessage;
    }
  }

  changeEmail = async (e, providerId) => {
    e.preventDefault();
    const user = this.auth.currentUser, {
      uid
    } = user;
    let result = null,
      newEmail = null,
      errorMessage = null;
    try {
      const userCredentials = await this.getUserCredentials(providerId);
      if (userCredentials && typeof userCredentials !== 'string' && userCredentials.user) {
        result = await swal.fire({
          title: 'Change Email',
          input: 'email',
          inputPlaceholder: 'Enter your new email',
          showCancelButton: true
        });
        newEmail = result.value;
        if (newEmail) {
          try {
            await user.updateEmail(newEmail);
            if (!user.emailVerified) {
              await user.sendEmailVerification();
              await this.saveDbUser({
                uid: uid,
                email: newEmail,
                updatedBy: uid
              });
              await this.signOut();
              await swal.fire({
                type: 'success',
                title: 'Change Email Successful',
                text: 'Please verify your email to login.'
              });
              return;
            }
          } catch (error) {
            errorMessage = `Unable to update your email to <b>' + newEmail + '</b>. Reason: ${error.message}`;
          }
        }
      } else {
        errorMessage = userCredentials;
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      swal.fire({
        type: 'error',
        title: 'Change Email Error',
        html: errorMessage
      });
      console.log(`Change Email Error: ${errorMessage}`);
    }
  }

  changePassword = async (e, providerId) => {
    e.preventDefault();
    let result = null,
      newPassword = null,
      confirmPassword = null,
      errorMessage = null;
    try {
      const userCredentials = await this.getUserCredentials(providerId);
      if (userCredentials && typeof userCredentials !== 'string' && userCredentials.user) {
        result = await swal.fire({
          title: 'New Password',
          input: 'password',
          inputPlaceholder: 'Enter your new password',
          showCancelButton: true
        });
        newPassword = result.value;
        result = await swal.fire({
          title: 'Confirm Password',
          input: 'password',
          inputPlaceholder: 'Re-enter your new password',
          showCancelButton: true
        });
        confirmPassword = result.value;
        if (newPassword && confirmPassword && newPassword === confirmPassword) {
          try {
            await this.updatePassword(newPassword);
            await this.signOut();
            await swal.fire({
              type: 'success',
              title: 'Change Password Successful',
              text: 'Please login again to confirm your new password.'
            });
            return;
          } catch (error) {
            errorMessage = `Unable to update your password to <b>' + newPassword + '</b>. Reason: ${error.message}`;
          }
        } else {
          errorMessage = 'New Password does not match Confirm Password.';
        }
      } else {
        errorMessage = userCredentials;
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      swal.fire({
        type: 'error',
        title: 'Change Password Error',
        html: errorMessage
      });
      console.log(`Change Password Error: ${errorMessage}`);
    }
  }

  updatePassword = async password => {
    return await this.auth.currentUser.updatePassword(password);
  }

  authUserListener = async (next, fallback) => {
    const getPhotoDownloadURL = async photoURL => {
      let photoDownloadURL = '';
      try {
        photoDownloadURL = photoURL
          ? await this.getStorageFileDownloadURL(photoURL)
          : '';
      } catch (error) {
        const {
          message: errorMessage
        } = error;
        console.log(`Auth User Listener Error: ${errorMessage}`);
        throw new Error(errorMessage);
      } finally {
        return photoDownloadURL;
      }
    };
    return await this.auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        const existingDbUser = await this.getDbUser(authUser.uid),
          snapshot = await existingDbUser.once('value'),
          dbUser = await snapshot.val(),
          photoDownloadURL = await getPhotoDownloadURL(dbUser.photoURL);
        if (dbUser && !dbUser.roles) {
          dbUser.roles = {
            basicRole
          };
        }
        authUser = {
          uid: authUser.uid,
          email: authUser.email,
          emailVerified: authUser.emailVerified,
          providerData: authUser.providerData,
          ...dbUser,
          photoDownloadURL
        };
        next(authUser);
      } else {
        fallback();
      }
    })
  }

  deleteAccount = async e => {
    e.preventDefault();
    let result = null,
      errorMessage = null;
    try {
      result = await swal.fire({
        type: 'warning',
        title: 'Are you sure?',
        text: "You won't be able to undo this!",
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-outline-link',
        }
      });
      if (!!result.value) {
        const user = this.auth.currentUser,
          providerId = user.providerData[0].providerId,
          userCredentials = await this.getUserCredentials(providerId);
        if (userCredentials && typeof userCredentials !== 'string' && userCredentials.user) {
          if (providerId === 'password' && userCredentials.user.photoURL) {
            await this.deleteStorageFile(userCredentials.user.photoURL);
          }
          await this.deleteDbUser(userCredentials.user.uid);
          await userCredentials.user.delete();
          //await this.signOut();
          swal.fire({
            type: 'success',
            title: 'Delete Account Successful',
            text: 'Your account has been deleted.'
          });
        } else {
          errorMessage = userCredentials;
        }
      }
    } catch (error) {
      errorMessage = error.message;
    }
    if (errorMessage) {
      swal.fire({
        type: 'error',
        title: 'Delete Account Error',
        html: errorMessage
      });
      console.log(`Delete Account Error: ${errorMessage}`);
    }
  }

  /*** DB APIs ***/
  // Users
  getDbUsers = async () => {
    return await this.db.ref('users');
  }

  getDbUser = async uid => {
    return await this.db.ref(`users/${uid}`);
  }

  saveDbUser = async (user, saveDbUser_completed) => {
    const {
      active,
      created,
      createdBy,
      displayName,
      email,
      photoURL,
      providerData,
      roles,
      uid,
      updated,
      updatedBy
    } = user,
      existingDbUser = await this.getDbUser(uid),
      now = new Date();
    let errorMessage = null,
      dbUserRef = null,
      dbUser = null;
    if (existingDbUser) {
      dbUserRef = await existingDbUser.once('value');
      dbUser = dbUserRef.val();
      if (dbUser) {
        user = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbUser.active),
          created: created || dbUser.created,
          createdBy: createdBy || dbUser.createdBy,
          displayName: displayName || dbUser.displayName || '',
          email: email || dbUser.email,
          photoURL: photoURL || dbUser.photoURL || '',
          providerData: providerData || dbUser.providerData || {},
          roles: roles || dbUser.roles || {
            basicRole
          },
          uid: uid,
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        }
      }
      existingDbUser.set(user, saveDbUser_completed);
    } else {
      errorMessage = `Save Db User Error: uid (${uid})`;
    }
    if (errorMessage) {
      console.log(`Save Db User Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  deleteDbUser = async uid => {
    const existingDbUser = await this.getDbUser(uid);
    let errorMessage = null;
    if (existingDbUser) {
      await existingDbUser.remove();
    } else {
      errorMessage = `Delete Db User Error: uid (${uid})`;
    }
    if (errorMessage) {
      console.log(`Delete Db User Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  // Gallery Images
  getDbGalleryImages = async () => {
    return await this.db.ref('galleryImages');
  }

  getDbGalleryImage = async giid => {
    return await this.db.ref(`galleryImages/${giid}`);
  }

  saveDbGalleryImage = async (galleryImage, saveDbGalleryImage_completed) => {
    const {
      active,
      created,
      createdBy,
      alt,
      giid,
      imageURL,
      monthYear,
      title,
      description,
      updated,
      updatedBy,
      uid
    } = galleryImage,
      now = new Date();
    let errorMessage = null,
      existingDbGalleryImage = await this.getDbGalleryImage(giid),
      dbGalleryImageRef = null,
      dbGalleryImage = null;
    if (!giid) {
      dbGalleryImageRef = await existingDbGalleryImage.push();
      galleryImage = {
        active: active,
        created: created,
        createdBy: createdBy,
        alt: alt || '',
        giid: await dbGalleryImageRef.getKey(),
        imageURL: imageURL || '',
        monthYear: monthYear || '',
        title: title || '',
        description: description || '',
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbGalleryImageRef.set(galleryImage, saveDbGalleryImage_completed);
    } else {
      dbGalleryImageRef = await existingDbGalleryImage.once('value');
      dbGalleryImage = await dbGalleryImageRef.val();
      if (dbGalleryImage) {
        galleryImage = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbGalleryImage.active),
          created: created || dbGalleryImage.created,
          createdBy: createdBy || dbGalleryImage.createdBy,
          alt: alt || dbGalleryImage.alt || '',
          giid: giid,
          imageURL: imageURL || dbGalleryImage.imageURL || '',
          monthYear: monthYear || dbGalleryImage.monthYear || '',
          title: title || dbGalleryImage.title || '',
          description: description || dbGalleryImage.description || '',
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbGalleryImage.set(galleryImage, saveDbGalleryImage_completed);
      } else {
        errorMessage = `Save Db Gallery Image Error: giid (${giid})`;
      }
    }
    if (errorMessage) {
      console.log(`Save Db Gallery Image Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  deleteDbGalleryImage = async giid => {
    const existingDbGalleryImage = await this.getDbGalleryImage(giid);
    let errorMessage = null;
    if (existingDbGalleryImage) {
      await existingDbGalleryImage.remove();
    } else {
      errorMessage = `Delete Db Gallery Image Error: giid (${giid})`;
    }
    if (errorMessage) {
      console.log(`Delete Db Gallery Image Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  // Events
  getDbEvents = async () => {
    return await this.db.ref('events');
  }

  getDbEvent = async eid => {
    return await this.db.ref(`events/${eid}`);
  }

  saveDbEvent = async (event, saveDbEvent_completed) => {
    const {
      active,
      created,
      createdBy,
      date,
      eid,
      endTime,
      monthYear,
      name,
      startTime,
      synopsis,
      updated,
      updatedBy,
      uid
    } = event,
      now = new Date();
    let errorMessage = null,
      existingDbEvent = await this.getDbEvent(eid),
      dbEventRef = null,
      dbEvent = null;
    if (!eid) {
      dbEventRef = await existingDbEvent.push();
      event = {
        active: active,
        created: created,
        createdBy: createdBy,
        date: date || '',
        eid: await dbEventRef.getKey(),
        endTime: endTime || '',
        monthYear: monthYear || '',
        name: name || '',
        startTime: startTime || '',
        synopsis: synopsis || '',
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbEventRef.set(event, saveDbEvent_completed);
    } else {
      dbEventRef = await existingDbEvent.once('value');
      dbEvent = await dbEventRef.val();
      if (dbEvent) {
        event = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbEvent.active),
          created: created || dbEvent.created,
          createdBy: createdBy || dbEvent.createdBy,
          date: date || dbEvent.date || '',
          eid: eid,
          endTime: endTime || dbEvent.endTime || '',
          monthYear: monthYear || dbEvent.monthYear || '',
          name: name || dbEvent.name || '',
          startTime: startTime || dbEvent.startTime || '',
          synopsis: synopsis || dbEvent.synopsis || '',
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbEvent.set(event, saveDbEvent_completed);
      } else {
        errorMessage = `Save Db Event Error: eid (${eid})`;
      }
    }
    if (errorMessage) {
      console.log(`Save Db Event Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  deleteDbEvent = async eid => {
    const existingDbEvent = await this.getDbEvent(eid);
    let errorMessage = null;
    if (existingDbEvent) {
      await existingDbEvent.remove();
    } else {
      errorMessage = `Delete Db Event Error: eid (${eid})`;
    }
    if (errorMessage) {
      console.log(`Delete Db Event Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  // Business Profiles
  getDbBusinessProfiles = async () => {
    return await this.db.ref('businessProfiles');
  }

  getDbBusinessProfile = async bpid => {
    return await this.db.ref(`businessProfiles/${bpid}`);
  }

  saveDbBusinessProfile = async (businessProfile, saveDbBusinessProfile_completed) => {
    const {
      active,
      created,
      createdBy,
      bpid,
      name,
      description,
      backgroundImage,
      logo,
      physicalAddress,
      postalAddress,
      contactPerson,
      updated,
      updatedBy,
      uid
    } = businessProfile,
      now = new Date();
    let errorMessage = null,
      existingDbBusinessProfile = await this.getDbBusinessProfile(bpid),
      dbBusinessProfileRef = null,
      dbBusinessProfile = null;
    if (!bpid) {
      dbBusinessProfileRef = await existingDbBusinessProfile.push();
      businessProfile = {
        active: active,
        created: created,
        createdBy: createdBy,
        bpid: await dbBusinessProfileRef.getKey(),
        name: name || '',
        description: description || '',
        backgroundImage: backgroundImage || '',
        logo: logo || '',
        physicalAddress: physicalAddress || '',
        postalAddress: postalAddress || '',
        contactPerson: contactPerson || '',
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbBusinessProfileRef.set(businessProfile, saveDbBusinessProfile_completed);
    } else {
      dbBusinessProfileRef = await existingDbBusinessProfile.once('value');
      dbBusinessProfile = await dbBusinessProfileRef.val();
      if (dbBusinessProfile) {
        businessProfile = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbBusinessProfile.active),
          created: created || dbBusinessProfile.created,
          createdBy: createdBy || dbBusinessProfile.createdBy,
          bpid: bpid,
          name: name || dbBusinessProfile.name || '',
          description: description || dbBusinessProfile.description || '',
          backgroundImage: backgroundImage || dbBusinessProfile.backgroundImage || '',
          logo: logo || dbBusinessProfile.logo || '',
          physicalAddress: physicalAddress || dbBusinessProfile.physicalAddress || '',
          postalAddress: postalAddress || dbBusinessProfile.postalAddress || '',
          contactPerson: contactPerson || dbBusinessProfile.contactPerson || '',
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbBusinessProfile.set(businessProfile, saveDbBusinessProfile_completed);
      } else {
        errorMessage = `Save Db Business Profile Error: bpid (${bpid})`;
      }
    }
    if (errorMessage) {
      console.log(`Save Db Business Profile Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  deleteDbBusinessProfile = async bpid => {
    const existingDbBusinessProfile = await this.getDbBusinessProfile(bpid);
    let errorMessage = null;
    if (existingDbBusinessProfile) {
      await existingDbBusinessProfile.remove();
    } else {
      errorMessage = `Delete Db Business Profile Error: bpid (${bpid})`;
    }
    if (errorMessage) {
      console.log(`Delete Db Business Profile Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  // Settings
  getDbSettings = async sid => {
    return sid
      ? await this.db.ref(`settings/${sid}`)
      : await this.db.ref('settings');
  }

  saveDbSettings = async (settings, saveDbSettings_completed) => {
    const {
      active,
      created,
      createdBy,
      sid,
      name,
      badWordsList,
      badWordsExcludedList,
      updated,
      updatedBy,
      uid
    } = settings,
      now = new Date();
    let errorMessage = null,
      existingDbSettings = await this.getDbSettings(sid),
      dbSettingsRef = null,
      dbSettings = null;
    if (!sid) {
      dbSettingsRef = await existingDbSettings.push();
      settings = {
        active: active,
        created: created,
        createdBy: createdBy,
        sid: await dbSettingsRef.getKey(),
        name: name || '',
        badWordsList: badWordsList || [],
        badWordsExcludedList: badWordsExcludedList || [],
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbSettingsRef.set(settings, saveDbSettings_completed);
    } else {
      dbSettingsRef = await existingDbSettings.once('value');
      dbSettings = await dbSettingsRef.val();
      if (dbSettings) {
        settings = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbSettings.active),
          created: created || dbSettings.created,
          createdBy: createdBy || dbSettings.createdBy,
          sid: sid,
          name: name || dbSettings.name || '',
          badWordsList: badWordsList || dbSettings.badWordsList || [],
          badWordsExcludedList: badWordsExcludedList || [],
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbSettings.set(settings, saveDbSettings_completed);
      } else {
        errorMessage = `Save Db Settings Error: sid (${sid})`;
      }
    }
    if (errorMessage) {
      console.log(`Save Db Settings Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  deleteDbSettings = async sid => {
    const existingDbSettings = await this.getDbSettings(sid);
    let errorMessage = null;
    if (existingDbSettings) {
      await existingDbSettings.remove();
    } else {
      errorMessage = `Delete Db Settings Error: sid (${sid})`;
    }
    if (errorMessage) {
      console.log(`Delete Db Settings Error: ${errorMessage}`);
    }
  }

  // ContactMessages
  getDbContactMessages = async () => {
    return await this.db.ref('contactMessages');
  }

  getDbContactMessage = async cmid => {
    return await this.db.ref(`contactMessages/${cmid}`);
  }

  saveDbContactMessage = async (contactMessage, saveDbContactMessage_completed) => {
    const {
      active,
      created,
      createdBy,
      cmid,
      name,
      email,
      message,
      updated,
      updatedBy,
      uid
    } = contactMessage,
      now = new Date();
    let errorMessage = null,
      existingDbContactMessage = await this.getDbContactMessage(cmid),
      dbContactMessageRef = null,
      dbContactMessage = null;
    if (!cmid) {
      dbContactMessageRef = await existingDbContactMessage.push();
      contactMessage = {
        active: active,
        created: created,
        createdBy: createdBy,
        cmid: await dbContactMessageRef.getKey(),
        name: name || '',
        email: email || '',
        message: message || '',
        updated: updated || now.toString(),
        updatedBy: updatedBy || uid
      };
      dbContactMessageRef.set(contactMessage, saveDbContactMessage_completed);
    } else {
      dbContactMessageRef = await existingDbContactMessage.once('value');
      dbContactMessage = await dbContactMessageRef.val();
      if (dbContactMessage) {
        contactMessage = {
          active: (typeof active === 'boolean' && active) || (typeof active !== 'boolean' && !!dbContactMessage.active),
          created: created || dbContactMessage.created,
          createdBy: createdBy || dbContactMessage.createdBy,
          cmid: cmid,
          name: name || dbContactMessage.name || '',
          email: email || dbContactMessage.email || '',
          message: message || dbContactMessage.message || '',
          updated: updated || now.toString(),
          updatedBy: updatedBy || uid
        };
        existingDbContactMessage.set(contactMessage, saveDbContactMessage_completed);
      } else {
        errorMessage = `Save Db Contact Message Error: cmid (${cmid})`;
      }
    }
    if (errorMessage) {
      console.log(`Save Db Contact Message Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  deleteDbContactMessage = async cmid => {
    const existingDbContactMessage = await this.getDbContactMessage(cmid);
    let errorMessage = null;
    if (existingDbContactMessage) {
      await existingDbContactMessage.remove();
    } else {
      errorMessage = `Delete Db Contact Message Error: cmid (${cmid})`;
    }
    if (errorMessage) {
      console.log(`Delete Db Contact Message Error: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  }

  /*** Storage APIs ***/
  getStorageFileDownloadURL = async path => {
    return await this.storage.ref().child(path).getDownloadURL();
  }

  saveStorageFile = async (path, file) => {
    return await this.storage.ref().child(path).put(file);
  }

  deleteStorageFile = async (path) => {
    return await this.storage.ref().child(path).delete();
  }
}

export default Firebase;