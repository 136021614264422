const config = {
  apiKey: "AIzaSyB2pGXNdxZ1G7CQMINiuRVFywqBfTW9DRA",
  authDomain: "mai-marae-pwa-prod.firebaseapp.com",
  databaseURL: "https://mai-marae-pwa-prod.firebaseio.com",
  projectId: "mai-marae-pwa-prod",
  storageBucket: "mai-marae-pwa-prod.appspot.com",
  messagingSenderId: "874462881417",
  appId: "1:874462881417:web:90828ee70f6e85e6"
};

export default config;
