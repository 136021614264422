import React from 'react';
import {
  AuthUserContext,
  withFirebase
} from './index';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser'))
      };
    }
    componentDidMount = async () => {
      this.listener = await this.props.firebase.authUserListener(this.handleAuthUserListenerNext, this.handleAuthUserListenerFallback);
    }
    componentWillUnmount = () => {
      this.listener();
    }
    handleAuthUserListenerNext = authUser => {
      localStorage.setItem('authUser', JSON.stringify(authUser));
      this.setState({
        authUser
      });
    }
    handleAuthUserListenerFallback = () => {
      localStorage.removeItem('authUser');
      this.setState({
        authUser: null
      });
    }
    render = () => {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(WithAuthentication);
};

export default withAuthentication;
